<template>
  <div>
    <v-dialog
      ref="dialog"
      v-model="showDateFieldDialog"
      persistent
      width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormatted"
            :label="label"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="rules"
            outlined
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dateField"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="showDateFieldDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="showDateFieldDialog = false"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
  name: "DateField",
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showDateFieldDialog: false,
    };
  },
  methods: {},
  computed: {
    dateFormatted() {
      if (!this.dateField) {
        return null;
      }
      return moment(this.dateField, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
    dateField: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
<style lang="scss">
</style>
