<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-form class="mx-5" ref="form" lazy-validation>
          <v-row>
            <v-col cols="12">
              <div>
                <v-autocomplete
                  label="Customer"
                  prepend-icon="mdi-account-cash-outline"
                  v-model="payload.customer_id"
                  item-text="label"
                  item-value="id"
                  :items="customers"
                  clearable
                  outlined
                  @change="getOriginalApprovalDate"
                  :rules="[() => requiredRule(payload, 'customer_id')]"
                ></v-autocomplete>
              </div>
              <div>
                <v-autocomplete
                  label="Certificate"
                  prepend-icon="mdi-certificate-outline"
                  v-model="payload.certificate_id"
                  item-text="name"
                  item-value="id"
                  :items="certificates"
                  @change="getOriginalApprovalDate"
                  :rules="[() => requiredRule(payload, 'certificate_id')]"
                  clearable
                  outlined
                />
              </div>
              <div>
                <v-text-field
                  v-model="payload.audit_dates"
                  label="Audit dates"
                  prepend-icon="mdi-format-text-variant-outline"
                  outlined
                ></v-text-field>
              </div>
                <DateField
                  label="Start date"
                  v-model="payload.start_date"
                  :rules="[() => requiredRule(payload, 'start_date')]">
                </DateField>
                <DateField
                  label="Original approval date"
                  v-model="payload.original_approval_date"
                  :rules="[() => requiredRule(payload, 'original_approval_date')]">
                </DateField>
                <DateField
                  label="Current certificate date"
                  v-model="payload.current_certificate_date"
                  :rules="[() => requiredRule(payload, 'current_certificate_date')]">
                </DateField>
                <DateField
                  label="Expire date"
                  v-model="payload.certificate_expiry_date"
                  :rules="[() => requiredRule(payload, 'certificate_expiry_date')]">
                </DateField>
              <v-btn
                color="primary"
                class="mt-4 float-right"
                :loading="getCustomerCertificatesLoading"
                :disabled="getCustomerCertificatesLoading"
                @click="submit"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { getOptions as getCustomerOptions } from "@/services/customerService";
import { getOptions as getCertificateOptions } from "@/services/certificateService";
import { getOriginalApprovalDate } from "@/services/customerCertificateService";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import DateField from "@/components/DateField";

export default {
  metaInfo: {
    title: "Form"
  },
  components: { DateField },
  data() {
    return {
      isCreate: _.get(this.$route, "meta.kind") === "create",
      id: _.get(this.$route, "params.id"),
      customerCertificate: null,
      customers: [],
      certificates: [],
      showCreatedAtDialog: false,
      payload: {
        customer_id: null,
        certificate_id: null,
        start_date: null,
        original_approval_date: null,
        current_certificate_date: null,
        certificate_expiry_date: null,
        audit_dates: null,
      },
    };
  },
  created: function() {
    getCustomerOptions().then(({data}) => this.customers = data);
    getCertificateOptions().then(({data}) => this.certificates = data);
  },
  mounted: function() {
    if (this.id) {
      this.customerCertificate = this.$store.getters.getCustomerCertificate(+this.id);
      this.payload = {
        customer_id: _.get(this.customerCertificate, 'customer.id'),
        certificate_id: _.get(this.customerCertificate, 'certificate.id'),
        start_date: _.get(this.customerCertificate, 'start_date'),
        original_approval_date: _.get(this.customerCertificate, 'original_approval_date'),
        current_certificate_date: _.get(this.customerCertificate, 'current_certificate_date'),
        certificate_expiry_date: _.get(this.customerCertificate, 'certificate_expiry_date'),
        audit_dates: _.get(this.customerCertificate, 'audit_dates')
      };
    }
  },
  methods: {
    ...mapActions(["createCustomerCertificate", "updateCustomerCertificate"]),
    requiredRule(obj, key) {
      return !!_.get(obj, key);
    },
    async getOriginalApprovalDate() {
      if (this.payload.customer_id && this.payload.certificate_id) {
        const { data } = await getOriginalApprovalDate({
          customer_id: this.payload.customer_id,
          certificate_id: this.payload.certificate_id,
        });
        if (data.original_approval_date) {
          this.$set(this.payload, 'original_approval_date', data.original_approval_date);
        }
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }
      this.$store.dispatch("setCustomerCertificatesLoading", true);
      try {
        this.isCreate
          ? await this.createCustomerCertificate(this.payload)
          : await this.updateCustomerCertificate({ id: this.id, payload: this.payload });
        this.$store.dispatch(
          "showSnackbar",
          `${_.startCase(this.payload.name)} certificate saved.`
        );
        this.$router.push("/app/customer-certificate");
      } catch (error) {
        console.error("DEBUG: error", error);
        this.$store.dispatch("showSnackbar", "Oops, something went wrong!");
        this.$store.dispatch("setCustomerCertificatesError", error);
      }

      this.$store.dispatch("setCustomerCertificatesLoading", false);
    }
  },
  computed: {
    ...mapGetters(["getCustomerCertificatesLoading"]),
    startedAtFormatted() {
      const startedAt = _.get(this.payload, 'start_date');
      if (!startedAt) {
        return null;
      }
      return moment(startedAt, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
    hasCertificateTemplate() {
      return !!_.get(this.certificate, "template");
    },
    pageModel() {
      return _.lowerCase(_.get(this.page, "model"));
    },
    hasPermissions() {
      return !_.isEmpty(this.page.actions) || "This field is required";
    },
    isStartedAtChanged() {
      return _.get(this.customerCertificate, 'start_date')
        !== _.get(this.payload, 'start_date')
    }
  }
};
</script>
<style lang="scss" scoped>
.pdf-template {
  display: inline-block;
  padding-bottom: 25px;
  width: 25%;
}
</style>
